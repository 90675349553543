import styled from 'styled-components';

export const Column = styled.div`
   @media (min-width: 782px) {
        width:50%;
        padding:0 16px;
        display:inline-block;
        vertical-align:top;
   }
    p {
        margin-bottom:18px;
    }
    .gMap {
        margin: 64px 0 24px;
        border:1px solid ${props => props.theme.color.primary};
    }
    .gmarker {
        background-color: #FFFFFF;
    }
    .vcard {
        text-align:right;
    }
`;
