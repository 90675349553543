import React from 'react';
//import styled from 'styled-components';
//import {StaticQuery, graphql, Link} from 'gatsby';
//import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Basic = (props) => (
    <Section id={props.node.slug} key={props.node.id}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: props.node.title}} />
        <div dangerouslySetInnerHTML={{ __html: props.node.content}} />
      </Container>
    </Section>
);



export default Basic;
