import React from 'react';
import Modal from 'react-modal';


import { Wrapper } from './style';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        zIndex                : '1000',
        padding               : '50px',
        width: '1100px',
        maxWidth : '100%',
        boxShadow: '6px 6px 12px rgba(0, 0, 0, 0.4)',

    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby')

class FundItem extends React.Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
        // this.para.style.margin= '';

    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    render() {
        const fund = this.props.fund
        return (
            <Wrapper>
                <div className="fund-item">
                    <h3 className="fund-title">
                        <span dangerouslySetInnerHTML={{__html: fund.title}}/>
                    </h3>
                    <div className="fund-content">
                        <p><strong>Scope:</strong> {fund.acf.scope}</p>
                        <p><strong>Funding:</strong> {fund.acf.funding}</p>
                        <p><strong>Closes:</strong> {fund.acf.closes}</p>
                    </div>
                    <button onClick={this.openModal}>more info</button>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel={fund.title}
                    >

                        {/*<h2 ref={subtitle => this.subtitle = subtitle}>{fund.title}</h2>*/}
                        <h2 dangerouslySetInnerHTML={{__html: fund.title}}/>
                        <div className="fund-modal-content">
                        <p ref={para => this.para = para}><strong>Scope:</strong> {fund.acf.scope}</p>
                        <p><strong>Funding:</strong> {fund.acf.funding}</p>
                        <p><strong>Closes:</strong> {fund.acf.closes}</p>

                        <div className="fund-info" dangerouslySetInnerHTML={{ __html: fund.content}} />

                            <button onClick={this.closeModal}>close</button>
                        </div>
                    </Modal>
                </div>
            </Wrapper>
        );
    }
}

export default FundItem;
