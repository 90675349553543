import React from 'react';
import styled from 'styled-components';

import Gmap from '@common/Contact/GoogleMap';
import Cform from '@common/Contact/ContactForm';

import { Section, Container } from '@components/global';



const Contact = (props) => {


    return (
        <Section id={props.node.slug} key={props.node.id}>
          <Container>
            <h2 dangerouslySetInnerHTML={{ __html: props.node.title}} />
            <div dangerouslySetInnerHTML={{ __html: props.node.content}} />
            <Row>
                <Cform />
                <Gmap />
            </Row>
          </Container>
        </Section>
    )
};

const Row = styled.div `
    @media (min-width: 782px) {
        margin:0 -16px;
    }
`


export default Contact;
