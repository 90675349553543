import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import Invent from '@static/process/Invent.svg';
import Search from '@static/process/Search.svg';
import Read from '@static/process/Read.svg';
import Partner from '@static/process/Partner.svg';
import Write from '@static/process/Write.svg';
import Submit from '@static/process/Submit.svg';


import Chevron from '@static/process/chevron.svg';

//import {StaticQuery, graphql, Link} from 'gatsby';
//import Img from 'gatsby-image';

import { Section, Container } from '@components/global';


const Process = (props) => {
    const [height, setHeight] = useState(0);

    const measuredRef = useCallback(node => {
        handleResize(node)
        window.addEventListener('resize',  () => { handleResize(node) });
    }, []);

    function handleResize(node) {
        if (node !== null) {
            const child = node.getElementsByClassName('submit-phase')
            let offset = child[0].children[1].offsetTop-13;
            //setHeight(node.getBoundingClientRect().height);
            //console.log(offset);
            setHeight(offset);
        }
    }

    const blockHeight = {
            top: height + 'px'
    };
    return (
        <Section id={props.node.slug} key={props.node.id} ref={measuredRef} accent>
            <Container >
                <h2 dangerouslySetInnerHTML={{ __html: props.node.title}} />
                <Steps dangerouslySetInnerHTML={{ __html: props.node.content}} />
            </Container>
            <Block style={blockHeight} />
        </Section>
    );
}




const Block = styled.div` 
    background-color:  ${props => props.theme.color.green.dark};
    position:absolute;
    bottom:0;
    top:330px;
    width:100%;
    z-index:0;
    @media (max-width: ${props => props.theme.screen.sm}) {
        background-color: transparent;
    }
`
const Steps = styled.div`
    .application-process-row {
        display:flex;
        flex-wrap: wrap;
        text-align:center;
        .process-step {
            width:16.666%;
            
            padding-top:134px;
            padding-bottom:31px;
            z-index:10;
            h3 {
                font-family: ${props => props.theme.font.primary};
                color: ${props => props.theme.color.primary};
                text-transform:uppercase;
                ${props => props.theme.font_size.xlarge};
                margin-bottom:21px;
                background-image: url(${Chevron});
                background-repeat:no-repeat;
                background-position:right;
                background-size: 15px 27px;
                padding:0 16px;
            }
            p {
               color: ${props => props.theme.color.green.light};
               ${props => props.theme.font_size.regular};
               background-color: ${props => props.theme.color.green.dark};
               padding:0 16px;
                min-height:78px;
               a {
                color: ${props => props.theme.color.green.light};
               }
            }
            background-repeat:no-repeat;
            background-size: 128px 110px;
            background-position: top;
            &.invent-phase {
                background-image: url(${Invent});
            }
            &.search-phase {
                background-image: url(${Search});
            }
            &.read-phase {
                background-image: url(${Read});
            }
            &.partner-phase {
                background-image: url(${Partner});
            }
            &.write-phase {
                background-image: url(${Write});
            }
            &.submit-phase {
                background-image: url(${Submit});
                h3 {
                    background-image:none;
                }
            }
            
            
            @media (max-width: ${props => props.theme.screen.md}) {
                min-width:33%;
            }  
           @media (max-width: ${props => props.theme.screen.sm}) {
                min-width:100%;
                display:flex;
                justify-content: space-between;
                align-items:center;
                padding-top:31px;
                border-bottom:1px solid ${props => props.theme.color.green.dark};
                &:last-child {
                border-bottom:none;
                }
                
                background-size: 85px 73px;
                background-position: center;
            
                p {
                    background-color: transparent;
                    text-align:right;
                    padding:0 0 0 50px;
                    width:50%;
                    min-height:auto;
                }
                h3 {
                    width:50%;
                    text-align:left;
                    background-image:none;
                    margin-bottom:0;
                    padding:0 50px 0 0;
                }
            } 
            
            
        }
    }
`;

export default Process;
