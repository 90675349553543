//import React from 'react';
import React, { useEffect } from 'react';
//import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import FundItem from '@common/FundItem';
import Button from '@common/Buttons/Contact';


import Flickity from 'react-flickity-component';


import { Section, Container } from '@components/global';



//import Img from 'gatsby-image';


const Funds = (props) => {
    // flkty must be declared here so the ref can refer to it
    let flkty = React.createRef();

    const flickityOptions = {
        initialIndex: 0,
        autoPlay: true,
        prevNextButtons: true,
        pageDots: false,
        setGallerySize: true,
        contain: true,
        imagesLoaded: true,
        resize: true,
        arrowShape: 'M 10,50 L 60,100 L 75,85 L 40,50 L 75,15 L 60,0 Z',
        wrapAround: true
        // advance cells every 3 seconds
    }
    const FundsQuery = useStaticQuery(graphql`
        query FundsQuery {
          allWordpressWpFundPostType {
            edges {
              node {
                id
                slug
                title
                content
                acf {
                  closes
                  funding
                  scope
                }
              }
            }
          }
        }
      `)
    const funds = FundsQuery.allWordpressWpFundPostType.edges
    useEffect(() => {
        const resize = flkty.resize;
        flkty.resize = () => {

            // reset heights
            flkty.cells.map((cell) => {
                return cell.element.style.height = cell.element.firstElementChild.style.height = 'auto'
            })
            resize.call(flkty)
            flkty.cells.map((cell) => {
                // these darn slides need sizing help.
                return cell.element.style.height = cell.element.firstElementChild.style.height = flkty.viewport.style.height;
            })

        }
        setTimeout(() => {
            // timeout as not calculating height correctly on first call enough!
            flkty.resize();
        }, 500);

    }, [funds, flkty])

    return (
        <>
            <Section id={props.node.slug} key={props.node.id}>
                <Container>
                    <h2 dangerouslySetInnerHTML={{__html: props.node.title}}/>

                    <div dangerouslySetInnerHTML={{__html: props.node.content}}/>
                    <div className="wp-block-columns fund-blocks">

                        <Flickity
                            flickityRef={c => flkty = c}
                            className={'funds-carousel'} // default ''
                            elementType={'div'} // default 'div'
                            options={flickityOptions} // takes flickity options {}
                            disableImagesLoaded // default false
                            reloadOnUpdate={true} // default false
                            static={true} // default false
                        >
                    {funds.map(( fund ) => {
                        return (
                            <FundItem key={fund.node.id} fund={fund.node} />
                        )}
                    )}
                        </Flickity>
                    </div>
                </Container>

            </Section>
            <Button slug="get-in-touch" title="GET IN TOUCH" top="-49px" />
         </>
    )
};



export default Funds;
