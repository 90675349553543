import React, { Component } from 'react';
import { graphql } from "gatsby";

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import Button from '@common/Buttons/Contact';
import SectionComponent from '@sections/SectionComponent';
import Footer from '@sections/Footer';


class IndexPage extends Component {

    render() {
        const data = this.props.data.allWordpressPage.edges
        return (
            <Layout>
                <Navbar />
                <Header/>
                <Button slug="get-in-touch" title="GET IN TOUCH" />
                {data.map(({ node }) => (
                    <SectionComponent node={node} key={node.id} />
                ))}

                <Footer/>
            </Layout>
        )
    }
}

export default IndexPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(sort: {fields: menu_order, order: ASC}, filter: {acf: {is_on_homepage: {eq: "yes"}}}) {
      edges {
        node {
          id
          title
          content
          slug
          acf {
            is_on_homepage
          }
        }
      }
    }
  }
`