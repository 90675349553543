import React, {useEffect} from 'react';
//import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import Button from '@common/Buttons/Contact';

import QuoteMarks from '@static/deco/quote-marks.svg';

import Flickity from 'react-flickity-component';
import { Section, Container } from '@components/global';
import styled from "styled-components";

const Testimonials = (props) => {
    let flkty = React.createRef();

    const flickityOptions = {
        initialIndex: 0,
        autoPlay: true,
        prevNextButtons: true,
        pageDots: false,
        setGallerySize: true,
        contain: true,
        imagesLoaded: true,
        resize: true,
        arrowShape: 'M 10,50 L 60,100 L 75,85 L 40,50 L 75,15 L 60,0 Z',
        wrapAround: true
        // advance cells every 3 seconds
    }

    const QuotesQuery = useStaticQuery(graphql`
        query QuotesQuery {
          allWordpressWpTestimonials {
            edges {
              node {
                id
                title
                slug
                content
                featured_media {
                  localFile {
                    childImageSharp {
                      fixed(width: 170) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `)
    const quotes = QuotesQuery.allWordpressWpTestimonials.edges
    useEffect(() => {
        //console.log('useeffect triggered')
        const resize = flkty.resize;
        flkty.resize = () => {

            // reset heights
            flkty.cells.map((cell) => {
                return cell.element.style.paddingTop = '0'
            })
            resize.call(flkty)
            flkty.cells.map((cell) => {
                let padding = ((flkty.viewport.clientHeight - cell.element.firstElementChild.offsetHeight)/2) - 32;
                // console.log( padding + 'px');
                // these darn slides need sizing help.
                return cell.element.style.paddingTop = padding + 'px';
            })

        }
        setTimeout(() => {
            // timeout as not calculating height correctly on first call enough!
            flkty.resize();
        }, 500);

    }, [quotes, flkty])

    return (
        <>
            <Button slug="get-in-touch" title="GET IN TOUCH" top="-49px" />
        <Section id={props.node.slug} key={props.node.id} accent>
            <Container>
                <h2 dangerouslySetInnerHTML={{__html: props.node.title}}/>
                <div dangerouslySetInnerHTML={{__html: props.node.content}}/>
                <Flickity
                    flickityRef={c => flkty = c}
                    className={'testimonials-carousel'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded // default false
                    reloadOnUpdate={true} // default false
                    static={true} // default false
                >
                {quotes.map(( quote ) => {
                    let featuredImg = undefined

                    if (quote.node.featured_media) {
                        featuredImg = quote.node.featured_media.localFile.childImageSharp.fixed
                    }

                    return (
                        <Slide className="slide" key={quote.node.id}>
                            <div className="review-card" id={quote.node.slug+'-testimonial'}>
                                <Quote src={QuoteMarks} alt="" />

                                {featuredImg && <Img fixed={featuredImg} />}
                                <div className="kind-words">
                                <blockquote dangerouslySetInnerHTML={{__html: quote.node.content}}/>
                                    <cite> {quote.node.title}</cite>
                                </div>
                                <Quote src={QuoteMarks} alt="" className="closing" />
                            </div>
                        </Slide>
                    )}
                )}
                </Flickity>
            </Container>
        </Section>
            </>
    )
};

const Quote = styled.img`
    width:78px;
    height:60px;
    position:absolute;
    left: -44px;
    top: -31px;
    opacity: 0;
    transform:scale(0.1) !important;
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    &.closing {
        left:auto;
        right:-44px;
        top:auto;
        bottom:-31px;
        transform: rotate(180deg) scale(0.1) !important;
    }
`;
const Slide  = styled.div`
    width:100%;
    padding: 0 32px;
    .review-card {
        position:relative;
        @media (min-width: 782px) {
            display:flex;
        }
        align-items:center;
        background-color: ${props => props.theme.color.white.regular};
          box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.3);
          padding:40px 32px;
          margin:32px auto;
          max-width:732px;
          color: ${props => props.theme.color.primary};
          
    }
    &.is-selected {
        img {
            transform:scale(1) !important;
            opacity: 1 !important;
            &.closing {
                transform: rotate(180deg) scale(1) !important;
            }
        }
    }
    .kind-words {
        display:inline-block;
        blockquote {
            margin:0;
            padding:0 0 24px;
            p {
                ${props => props.theme.font_size.regular};
                color: ${props => props.theme.color.primary};
            }
        }
        cite {
        font-style:normal;
        font-weight:700;
        }
    }
    .gatsby-image-wrapper {
        min-width:170px;
        margin: 0 auto;
        @media (min-width: 782px) {
            margin:0 32px 0 0;
        }
    }
`;



export default Testimonials;
