import React from 'react';
import styled from 'styled-components';
import { Column } from './style';

import Trapezoid from '@static/deco/trapezoid.svg';
const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.domRef = React.createRef()
        this.state = {
            name: '',
            email: '',
            mobile: '',
            message: '',
            gdpr_email: false,
            gdpr_phone: false
        };
        this.baseState = { ...this.state };
        this.state.feedbackMsg = null;


        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...this.state })
        })
            .then(() => {
                this.setState({
                    feedbackMsg: '<span style="color:green">Thank you '+this.state.name+', you’re form has been successfully sent!</span>',
                })
                this.setState({ ...this.baseState } );
            })
            .catch(error =>
                this.setState({
                    feedbackMsg: '<span style="color:red">Form could not be submitted.</span>',
                })
            );

        e.preventDefault();
    };
    render() {
        return (
            <Column>
            <MyForm ref={this.domRef}
                    method="post"
                    name="contact"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    onSubmit={event => this.handleSubmit(event)}
                    action="thanks"
            >
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type={'text'} name={'name'} id={'name'}
                           value={this.state.name}
                           onChange={this.handleInputChange} required={true}  />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type={'email'} name={'email'} id={'email'}
                           value={this.state.email}
                           onChange={this.handleInputChange} required={true}  />
                </div>
                <div className="form-group">
                    <label htmlFor="mobile">Mobile (optional)</label>
                    <input type={'text'} name={'mobile'} id={'mobile'}
                           value={this.state.mobile}
                           onChange={this.handleInputChange}  />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea name={'message'} id={'message'} rows="8" required={true}
                              value={this.state.message}
                              onChange={this.handleInputChange} />
                </div>
                <div className="form-group">
                    <small>From time to time, we may wish to send you information on our services. If you would like
                        to receive these updates select from the options below (your contact information will never
                        be given to third parties):</small>
                    <div className="gdpr">
                        <label>
                            <input type="checkbox"
                                      name="gdpr_email"
                                      checked={this.state.gdpr_email}
                                      onChange={this.handleInputChange}/>
                                      Email
                        </label>
                        <label>
                            <input type="checkbox"
                                   name="gdpr_phone"
                                   checked={this.state.gdpr_phone}
                                   onChange={this.handleInputChange}/>
                                   Phone
                        </label>
                    </div>
                </div>
                <div className="form-group hidden">
                    <input type="text" name="bot-field" />
                </div>
                <input type="hidden" name="form-name" value="contact" />
                <button type="submit">Submit</button>
                {this.state.feedbackMsg && <h3 style={{paddingBottom: '32px'}} dangerouslySetInnerHTML={{ __html: this.state.feedbackMsg}} />}

            </MyForm>
            </Column>
        );
    }
}

const MyForm = styled.form `
    margin: 24px 0;
    width:400px;
    max-width:100%;
    position:relative;
    .form-group {
        margin-bottom:18px;
        label {
            display:block;
        }
        &.hidden {
            display:none;
        }
    }
   
    label {
        ${props => props.theme.font_size.large};
        color: ${props => props.theme.color.primary};
        
    }
    .gdpr label {
            font-size:21px;
            line-height:30px;
            display:inline-block;
            margin-right:24px;
        }
    input,
    textarea {
        ${props => props.theme.font_size.regular};
        border:1px solid ${props => props.theme.color.primary};
        padding:8px;
        width:256px;
        max-width:100%;
    }
     input[type="checkbox"],
     input[type="radio"] {
        width:auto;
        margin-right:12px;
     }
    textarea {
        width:100%
    }
    button {
        //background-color:${props => props.theme.color.primary};
        
        background-image:url(${Trapezoid});
        background-repeat:no-repeat;
        width:137px;
        height:80px;
        color: ${props => props.theme.color.white.regular};
        font-family: ${props => props.theme.font.primary};
        ${props => props.theme.font_size.large};
        padding:4px 32px 12px;
        position:absolute;
        right:-16px;
    //    bottom:-32px;
        bottom:-60px;
        text-transform:uppercase;
        transition:transform 0.2s ease-in-out;
        transform: scale(1);
        
        &:hover {
            transform: scale(1.08);
        }
    }
`


export default ContactForm;