import React from 'react';
//import styled from 'styled-components';
//import {StaticQuery, graphql, Link} from 'gatsby';
//import Img from 'gatsby-image';

import Basic from '@sections/Basic';
import Funds from '@sections/Funds';
import Process from '@sections/Process';
import Testimonials from '@sections/Testimonials';
import Contact from '@sections/Contact';

// import { Section, Container } from '@components/global';

const SectionComponent = (props) => {
    // clever bit here where we short circuit section component based on slug
    const components = {
        'basic': Basic,
        'available-innovate-uk-funds': Funds,
        'application-process': Process,
        'happy-customers': Testimonials,
        'get-in-touch': Contact
    }
    // dynamic component reference
    let TagName = components[props.node.slug] ? components[props.node.slug] : components['basic'];
    return <TagName node={props.node} key={props.node.id} />
};



export default SectionComponent;

