import React from 'react';
import styled from 'styled-components';
import { Container } from '@components/global';
import Trapezoid from '@static/deco/trapezoid.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const ContactButton = (props) => {
    let adjust = {};
    if(props.top != null){
        adjust = {
            top: props.top
        };
    }
    return (
        <Button>
            <AnchorLink offset='50' href={`#${props.slug}`} style={adjust}>
                {props.title}
            </AnchorLink>
        </Button>
    )
}

const Button = styled(Container)`
    z-index:200;
    position:relative;
    overflow:visible;
    padding:0;
    a {
        background-image:url(${Trapezoid});
        background-repeat:no-repeat;
        width:180px;
        height:86px;
        color: ${props => props.theme.color.white.regular};
        font-family: ${props => props.theme.font.primary};
        ${props => props.theme.font_size.large};
        padding:28px 32px 20px;
        position:absolute;
        right:0;
        top:-69px;
        text-transform:uppercase;
        text-decoration:none;
        transition:transform 0.2s ease-in-out;
        transform: scale(1);
        @media (max-width: ${props => props.theme.screen.md}) {
            top:-34px;
        
        }
        &:hover {
            transform: scale(1.08);
        }
    }
`

export default ContactButton;