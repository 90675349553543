import React from 'react';
import styled from 'styled-components';
import { Column } from './style';

import {graphql, useStaticQuery} from "gatsby";
import GoogleMapReact from 'google-map-react';
import Marker from '@static/deco/marker.svg';


const MapMarker = ({ text }) => <div><Bubble src={Marker} alt={text.title} /></div>;

const Map = (props) => {

    const ContactQuery = useStaticQuery(graphql`
      {
        wordpressAcfOptions {
          options {
            email_address
            address_block
            google_api_key
            company_name
            location_map {
              address
              lat
              lng
            }
          }
        }
      }
    `)
    const options = ContactQuery.wordpressAcfOptions.options;
    const lat = Number(options.location_map.lat);
    const lng = Number(options.location_map.lng);
    const addr = {
        title: options.company_name,
        address: options.location_map.address
    };
    const defaultProps = {
        center: {
            lat: lat,
            lng: lng
        },
        zoom: 11
    };


    return (
        <Column>
            <div className="gMap" style={{ height: '330px', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: options.google_api_key }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <MapMarker
                        lat={lat}
                        lng={lng}
                        text={addr}
                        style={{backgroundColor:'#FFFFFF',width:'150px',padding:'16px'}}
                    />
                </GoogleMapReact>
            </div>
            <div className="vcard">
                <p className="org">{options.company_name}</p>
                <p className="adr" dangerouslySetInnerHTML={{ __html: options.address_block}} />
                <p><a className="email" href={'mailto:'+options.email_address}>{options.email_address}</a> </p>
            </div>
        </Column>
    )
};

const Bubble = styled.img`
    width:34px;
    height:52px;
    position:relative;
    left:-17px;
    top:-52px;
`;

export default Map;
