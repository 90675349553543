import styled from 'styled-components';

export const Wrapper = styled.div`

      position:relative;
      // flex-grow: 1;
      // margin-bottom: 21px;
      // flex-basis: 100%;
      word-break: break-word;
      overflow-wrap: break-word; 
      width:100%;
      padding:0 16px;
       transition: height 0.5s, transform 0.5s;
       transform:scale(0.9);
       z-index:4;
      @media (min-width: 782px) {
      
          width: 50%;
        
     }
    &.is-selected {
        transform:scale(1.1);
        z-index:5;
    }
  .fund-item {
      background-color: ${props => props.theme.color.white.regular};
      box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.3);
      // height:100%;
      width:100%;
      // display:flex;
      // flex-direction:column;
      //   align-self: stretch;
      position:relative;
       transition: height 0.5s;
  }

  .fund-title {
    padding:8px 28px 6px;
            background-color: ${props => props.theme.color.green.dark};
            color: ${props => props.theme.color.white.regular};
            font-family: ${props => props.theme.font.primary};
            text-align:center;
    ${props => props.theme.font_size.larger};
    text-transform:uppercase;
    // flex: none
    min-height: 98px;
    display:table;
    width:100%;
    > span {
    display:table-cell;
    vertical-align: middle;
    }
  }

  .fund-content {
    padding: 32px 28px 42px;
    // flex: 2 0;
    p {
        margin: 0 0 9px;
        ${props => props.theme.font_size.small};
        color: ${props => props.theme.color.primary};
    }
  }
  
    button {
        color: ${props => props.theme.color.green.dark};
        // align-self:flex-end;
        padding:8px 16px;
        // flex: none;
        position:absolute;
        bottom: 0;
        right: 0;
    }
    
`;
