import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';

import Flickity from 'react-flickity-component';

import { ReactComponent as Rocket } from '@images/slides/rocket.svg';
import { ReactComponent as Treasure } from '@images/slides/treasure.svg';
import { ReactComponent as Trophy } from '@images//slides/trophy.svg';

import border from '@static/deco/slider-border.svg';

const flickityOptions = {
  initialIndex: 0,
  autoPlay: true,
    prevNextButtons: false,
    pageDots: true,
  wrapAround: true
}

const SLIDES = [
  {
    slide: Rocket,
    message: '<h1>GOT A GREAT TECH IDEA?<br/>' +
        '<span class="blue">NEED A GRANT TO DEVELOP IT?</span><br />' +
        'THEN YOU’RE IN THE RIGHT PLACE...</h1>',
  },
  {
    slide: Treasure,
    message: '<h1>CONTACT OUR <span class="blue">GRANT WINNING EXPERTS</span><br/>' +
        'TO FIND OUT HOW WE CAN HELP YOU</h1>',
  },
  {
    slide: Trophy,
    message: '<h1>SO FAR WE’VE RAISED OVER <span class="blue">£20M</span> FOR<br/>' +
        'INNOVATIVE COMPANIES JUST LIKE YOURS</h1>',
  },
];


const Header = () => (
  <HeaderWrapper>
    <Container>
      <Flickity
              className={'carousel'} // default ''
              elementType={'div'} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static // default false
          >
          {SLIDES.map(({ slide, message }) => (
              <Grid key={slide} id={slide.name.toLowerCase()}>
                  <Text dangerouslySetInnerHTML={{ __html: message }} />
                <Art>
                  {slide()}
                </Art>
              </Grid>

          ))}
          </Flickity>
        </Container>
      </HeaderWrapper>
    );



const HeaderWrapper = styled.header`
    
    overflow: hidden;
  background-color: ${props => props.theme.color.green.regular};
    height:300px;
    background-image: url(${border});
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 45px;
  @media (max-width: ${props => props.theme.screen.md}) {
    height:auto;
  }
`;

const Art = styled.figure`
    width: 100%;
    margin: 0;
    max-height:300px;
    z-index:1;
    > svg {
     max-height:300px;
     }
  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    transform:scale(1.5);
  }
`;

const Text = styled.div`
z-index:2;
  justify-self: center;
   color: ${props => props.theme.color.white.regular};
    margin-bottom:45px;
   .blue {
        color: ${props => props.theme.color.primary}; 
   }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top:32px;
    margin-bottom:21px;
    text-align:center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 349px;
  align-items: center;
  grid-gap: 0;
  width:100%;
  opacity: 0;
  transition: opacity 0.5s;
  padding-right:80px;
  &.is-selected {
    opacity:1;
   }
  &#svgtrophy {
    grid-template-columns: 1fr 2fr;
    > ${Art} {
        order: 1;
    }
      
     > ${Text} {
        order: 2;
        //margin-top:21px;
    }
  }
  
  @media (max-width: ${props => props.theme.screen.md}) {
  padding-right:0;
  grid-template-columns: 2fr 1fr;
  padding-bottom:75px;
  
    // grid-template-columns: 1fr;
    // grid-gap: 0;
    // padding-right:0;
    //
    //    
    &#svgtrophy {
        grid-template-columns: 1fr 2fr;
    }
    
    //
    // > ${Art} {
    //     margin-top:21px;
    // }
  }
  @media (max-width: 400px) {
   > ${Text} {
   h1 {
   
    font-size:9vw;
    }
  }
  &#svgrocket {
     > ${Text} {
      h1 {
      font-size:7.4vw;
      }
     }
    }
  }
`;



export default Header;
